$(document).ready(function($) {

	var $app = $('html')  
	var $appContent = $('#page_content')
	var debug = true; if(typeof MH_BASIS_DEBUG != 'undefined') debug = true
	
	$app 
		.bind('init_app', function(e){
		
			if(debug) console.log("\n $app > " + e.type)	

			$app.trigger('init_fastClick')
			$app.trigger('init_ajaxLoad')
			$app.trigger('app_ready')
		
		})
		.bind('init_fastClick', function(e){
		
			if(debug) console.log("\n $app > " + e.type)		
			
			// still needed for iOS 8
			// https://github.com/ftlabs/fastclick			
			if (FastClick) {
				FastClick.attach(document.body);
			}
			
		})
		.bind('XXinit_ajaxLoad', function(e){	// ist raus, ist jetzt in der contentModules.js
		
			if(debug) console.log("\n $app > " + e.type)
			
			$app
				.on('click', '.foldOut__toggleBtn', function(e){
					
					var $foldOut__toggleBtn = $(this)
					var $foldOut__listItem = $foldOut__toggleBtn.parent()
					var $foldOut__toggleIndicator = $foldOut__toggleBtn.next()
					var $foldOut__ajaxContentWrapper = $foldOut__toggleIndicator.next()					
					var href = $foldOut__toggleBtn.attr('href')
					
					$foldOut__toggleBtn.toggleClass('foldOut__toggleBtn--closed foldOut__toggleBtn--open')
					$foldOut__toggleIndicator.toggleClass('foldOut__toggleIndicator--closed foldOut__toggleIndicator--open')
					$foldOut__ajaxContentWrapper.toggleClass('foldOut__ajaxContentWrapper--closed foldOut__ajaxContentWrapper--open')
					
					if(!$foldOut__ajaxContentWrapper.hasClass('foldOut__ajaxContentWrapper--loaded')){
						
						$foldOut__ajaxContentWrapper
							.load(href, function(){ $foldOut__ajaxContentWrapper.addClass('foldOut__ajaxContentWrapper--loaded') })								
						
					}
					
					e.preventDefault()
					
				})
			
		})
		.bind('app_ready', function(e){
		
			if(debug) console.log("\n $app > " + e.type)
			
			$app
				.addClass('app_ready')
			
		})
		.trigger('init_app')
	
}); 